<template>
    <div :class="['pathology-step', {active : step.id == periodActive }, {'disabled-step' : step.state === 1 || ( step.state === 2 && !step.todo ) }, {'finish' : step.state === 3}, { 'no-hover' : noHover }, { 'resp' : step.state === 2}]" @click="switchPeriod">
        <div :class="['step-item' , { 'other-injection' : step.otherInjection && this.$store.state.USER.role != 4  }]">
            {{ JSON.parse(step.name)[this.$store.state.LANG] }}
            <div class="pathology-todo" v-if="step.todo &&  ( step.id != 2 || $store.state.USER.role != 4) ">!</div>
        </div>
        <div :class="['date-display', { 'dateEnd' : step.state === 3 }]" v-if="step.id !=1 && step.date">{{new Date(step.date).toLocaleDateString('fr-FR')}}</div>
        <div class="pathology-line"></div>
    </div>
</template>

<script>

export default {
    name: "PathologyStep",
    props : ['step', 'periodActive',],
    data() {
        return {
            noHover : false
        }
    },
    methods : {
        switchPeriod() {
            if( this.$store.state.USER.role != 4 &&  ( this.step.state === 3 || (this.step.state === 2 && this.step.todo)  ) ) {
                this.$emit('switchPeriod', this.step.id, this.step.name )
                let arr = [1,6, 8];
                if(arr.includes(this.step.id)) {
                    this.$store.commit('setCurrentModule', 1)
                }else {
                    this.$store.commit('setCurrentModule', 2)
                }
            }
        }
    },
    mounted()  {
        let routeName = this.$route.name
        if(routeName === 'PatologyListDoctor' || routeName === 'PatologyListCoordinator' || routeName === 'PathologyList' ) {
            this.noHover = true
        }
    }

}
</script>

<style lang="scss" scoped>
    .pathology-step {
        width:120px;
        height:86px;
        display: flex;
        justify-content: center;
        position: relative;
        align-items: center;
        flex-direction: column;
    }

    .step-item {
        height:86px;
        width:80px;
        display: flex;
        justify-content: center;
        align-items: center;  
        font-size:15px;
        font-family: $fontMedium;
        color:$mainColor;
        border-radius: 50%;
        border:3px solid $mainColor;
        text-align: center;
        line-height: 18px;
        cursor: pointer;
        position:relative;
        z-index: 99;
        background: #fff;
    }

    .pathology-step.finish .step-item.other-injection {
        background:#0099cc;
        border-color:#0099cc;
    }

    .pathology-step.active .step-item, .pathology-step:not(.no-hover):hover .step-item {
        background: $mainColor;
        color:#fff;
    }

    .pathology-step.no-hover:hover .step-item {
        pointer-events: none;
    }

    .pathology-step.disabled-step  .step-item{
        pointer-events: none;
    }

    .pathology-step.disabled-step:hover .step-item {
        background: #fff;
        color:$mainColor;
    }

    .pathology-step.finish .step-item{
        background: $mainColor;
        color:#fff;
    }

    .pathology-todo {
        position: absolute;
        top:-5px;
        right:-5px;
        background: $error;
        border-radius: 50%;
        color:#fff;
        height:24px;
        width:24px;
        display: flex;
        justify-content: center;
        align-items: center;
        animation: bounce 0.7s ease infinite;
    }

    .date-display {
        position: absolute;
        bottom:-20px;
        font-size:12px;
        text-align: center;
        font-style:italic;
    }

    .date-display.dateEnd{
        font-family: $fontMedium;
        font-style: normal;
    }

    @keyframes bounce{
        from {top: -5px;}
        50%  {top: -10px;}
        to   {top: -5px;}
    }

    .pathology-line {
        position: absolute;
        top:calc(50% - 1px);
        left:0;
        right:0;
        height:2px;
        background: $mainColor;
        z-index: 9;
    }

    @media screen and (max-width:768px) {
        .pathology-step, .pathology-line{
            display: none;
        }

        .pathology-step.resp {
            display: flex;
        }
    }
</style>