<template>
    <div class="results-container" >
        <div class="row" v-if="scores || scoresMed">
            <div class="results-box" v-if="scoresMed && scoresMed.length > 0">
                <h2>{{ $t('score.med')}}</h2>
                <div class="results-list results-med">
                    <div class="results-periods">
                        <div class="results-periods-item" v-for="(item, idx) in scoresMed"  :key="idx">
                            M{{ item.months_number}}
                        </div>
                    </div>
                    <div class="results-item results-main">
                        <div class="results-name">{{ $t('score.global')}}</div>
                        <div class="results-circle-container">
                            <div class="results-circle-item" v-for="(item, idx) in scoresMed"  :key="idx">
                                <div :class="['results-circle', { 'green' : item.sm_global === 1}, { 'orange' : item.sm_global === 2}, { 'red' : item.sm_global === 3}]"></div>         
                            </div>
                        </div>
                    </div>
                    <div class="results-item">
                        <div class="results-name">{{ $t('score.part1')}}</div>
                        <div class="results-circle-container">
                            <div class="results-circle-item" v-for="(item, idx) in scoresMed"  :key="idx">
                                <div :class="['results-circle', { 'green' : item.sm_part_1 === 1}, { 'orange' : item.sm_part_1 === 2}, { 'red' : item.sm_part_1 === 3}]"></div>
                            </div>
                        </div>
                    </div>
                    <div class="results-item">
                        <div class="results-name">{{ $t('score.part2')}}</div>
                        <div class="results-circle-container">
                            <div class="results-circle-item" v-for="(item, idx) in scoresMed"  :key="idx">
                            <div :class="['results-circle', { 'green' : item.sm_part_2 === 1}, { 'orange' : item.sm_part_2 === 2}, { 'red' : item.sm_part_2 === 3}]"></div>
                            </div>
                        </div>
                    </div>
                    <div class="results-item">
                        <div class="results-name">{{ $t('score.part3')}}</div>
                        <div class="results-circle-container">
                            <div class="results-circle-item" v-for="(item, idx) in scoresMed"  :key="idx">
                                <div :class="['results-circle', { 'green' : item.sm_part_3 === 1}, { 'orange' : item.sm_part_3 === 2}, { 'red' : item.sm_part_3 === 3}]"></div>
                            </div>
                        </div>
                    </div>
                    <div class="results-item">
                        <div class="results-name">{{ $t('score.part4')}}</div>
                        <div class="results-circle-container">
                            <div class="results-circle-item" v-for="(item, idx) in scoresMed"  :key="idx">
                                <div :class="['results-circle', { 'green' : item.sm_part_4 === 1}, { 'orange' : item.sm_part_4 === 2}, { 'red' : item.sm_part_4 === 3}]"></div>
                            </div>
                        </div>
                    </div>
                    <div class="results-item">
                        <div class="results-name">{{ $t('score.part5')}}</div>
                        <div class="results-circle-container">
                            <div class="results-circle-item" v-for="(item, idx) in scoresMed"  :key="idx">
                                <div :class="['results-circle', { 'green' : item.sm_part_5 === 1}, { 'orange' : item.sm_part_5 === 2}, { 'red' : item.sm_part_5 === 3}]"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="results-box results-score" v-if="scores.current || scores.current === 0">
                <h2>{{ $t('score.scores')}} ({{ scores.type}}) : {{ JSON.parse(periodName)[$store.state.LANG]}} </h2>
                <div class="results-list scores-list">
                    <div :class="['results-item', 'scores-main',  getProgress(scores.color)  ]" v-if=" periodActive != 1 ">
                        <div class="results-name ">{{ JSON.parse(scores.text)[$store.state.LANG] }}</div>
                        <div class="results-value">{{ scores.progress }}</div>
                    </div>
                    <div class="results-item">
                        <div class="results-name">{{ $t('results.currentPeriod')}} {{ JSON.parse(periodName)[$store.state.LANG]}}</div>
                        <div class="results-value">                        
                            {{ scores.current }}<span class="score-max">/{{ max }}</span>                  
                        </div>
                    </div>
                    <div class="results-item" v-if="periodActive != 1">
                        <div class="results-name" >{{ $t('results.intervention')}}</div>
                        <div class="results-value">{{ scores.first }}<span class="score-max">/{{ max }}</span></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row row-secondary" v-if="douleurs || sports || antalgiques">
            <div class="results-box results-score" v-if="sports">
                <h2>{{ $t('score.sports')}} : {{ JSON.parse(periodName)[$store.state.LANG]}} </h2>
                <div class="results-list scores-list">
                    <div class="results-item" v-if="sports.current">
                        <div class="results-name">{{ $t('results.currentPeriod')}} {{ JSON.parse(periodName)[$store.state.LANG]}}</div>
                        <div class="results-value"> {{ JSON.parse(sports.current)[$store.state.LANG] }}</div>
                    </div>
                    <div class="results-item" v-if="sports.ai">
                        <div class="results-name" >{{ $t('results.intervention')}}</div>
                        <div class="results-value"> {{ JSON.parse(sports.ai)[$store.state.LANG] }}</div>
                    </div>
                </div>
            </div>
            <div class="results-box results-score" v-if="douleurs">
                <h2>{{ $t('score.douleurs')}} : {{ JSON.parse(periodName)[$store.state.LANG]}} </h2>
                <div class="results-list scores-list">
                    <div :class="['results-item', 'scores-main',  getDouleursColor()  ]" v-if=" douleurs.ai && douleurs.current ">
                        <div class="results-name " v-if="douleurs.progress <  0">{{ $t('score.amelioration')}}</div>
                        <div class="results-name " v-else-if="douleurs.progress > 0">{{ $t('score.deterioration')}}</div>
                        <div class="results-name " v-else>{{ $t('score.stable')}}</div>
                        <div class="results-value">{{ displayDouleurs(douleurs.progress)}}</div>
                    </div>
                    <div class="results-item" v-if="douleurs.current">
                        <div class="results-name">{{ $t('results.currentPeriod')}} {{ JSON.parse(periodName)[$store.state.LANG]}}</div>
                        <div class="results-value"> {{ douleurs.current - 1 }}</div>
                    </div>
                    <div class="results-item" v-if="douleurs.ai">
                        <div class="results-name" >{{ $t('results.intervention')}}</div>
                        <div class="results-value"> {{ douleurs.ai - 1 }}</div>
                    </div>
                </div>
            </div>
            <div class="results-box results-score" v-if="antalgiques">
                <h2>{{ $t('score.antalgiques')}} : {{ JSON.parse(periodName)[$store.state.LANG]}} </h2>
                <div class="results-list scores-list">
                    <div class="results-item" v-if="antalgiques.current">
                        <div class="results-name">{{ $t('results.currentPeriod')}} {{ JSON.parse(periodName)[$store.state.LANG]}}</div>
                        <div class="results-value"> {{ JSON.parse(antalgiques.current)[$store.state.LANG] }}</div>
                    </div>
                    <div class="results-item" v-if="antalgiques.ai">
                        <div class="results-name" >{{ $t('results.intervention')}}</div>
                        <div class="results-value"> {{ JSON.parse(antalgiques.ai)[$store.state.LANG] }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>  
</template>


<script>
import axios from 'axios'
import store from './../store'

export default {
    name :'Results',
    props : ['periodActive', 'max', 'periodName', 'refresh'],
    data(){
        return {
            scores : null,    
            scoresMed : null,
            douleurs : null,
            antalgiques : null,
            sports : null
        }
    },
    methods : {
        getData() {
            let vm = this

            let config = {
                validateStatus: () => true,
            }
            if(store.getters.getToken) {
                config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
            }

            axios
                .get(store.getters.getURL +"results/" + this.$route.params.id  + "/" +  this.periodActive, config)
                .then(function (response) {
                    if(response.status === 200) {
                        vm.scores = response.data.scores
                        vm.scoresMed = response.data.scores_med
                        vm.sports = response.data.sports
                        vm.douleurs = response.data.douleurs
                        vm.antalgiques = response.data.antalgiques
                        if(response.data.douleurs && response.data.douleurs.ai && response.data.douleurs.current) {
                            vm.douleurs.progress = response.data.douleurs.current - response.data.douleurs.ai
                        }
                    }else {
                        store.dispatch('addNotif', {type : 2, content : JSON.parse(response.data.message)[store.state.LANG]})
                    }
                })
                .catch((err) => {
                    store.dispatch('addNotif', {type : 2, content : err})
                });
        },
        getProgress(color) {
            if(color === 3 ) {
                return 'full-green'
            }else if(color === 1) {
                return 'red'
            }else if (color === 2) {
                return 'green'
            } else {
                return null
            }
        },
        displayDouleurs(progress) {
            return progress < 0 ? progress * -1 : progress
        },
        getDouleursColor() {
            if(this.douleurs.progress > 0) {
                return 'red'
            }else if(this.douleurs.progress < 0) {
                return 'green'
            }else {
                return null
            }
        }
    },
    mounted() {
        this.getData();
    },
    watch : {
        periodActive() {
            this.getData();
        },
        refresh() {
            this.getData();
        }
    }
}
</script>

<style lang="scss" scoped>
    .results-container {
        display: flex;
        flex-direction: column;
    }

    .results-container .row {
        display: flex;
        justify-content: space-between;
    }

    .results-box {
        margin:20px;
        width:calc(50% - 40px);
        border:1px solid $mainColor;
        border-radius: 10px;
        padding:20px;
    }

    .row-secondary .results-box {
        width:calc(30% - 40px);
    }

    .row-secondary .results-box .results-value {
        width:auto;
        max-width:auto;
        margin-right: 5px;
    }

    // .results-box.results-score {
    //     background: $mainColor;
    // }

    h2 {
        margin-bottom:10px;
        border-bottom:1px solid $mainColor;
        line-height: 2em;
        text-transform: uppercase;
        font-size:18px;
    }

    .results-score h2 {
        border-color:$mainColor;
    }

    .results-score .results-item {
        background:$inputBackground;
        margin:4px 0;
    }

    .results-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding:10px 10px;
    }

    .results-med .results-item:nth-child(odd) {
        background:$inputBackground;
    }

    .results-circle {
        width:25px;
        height:25px;
        min-width: 25px;
        border-radius:50%;
        background: $inputBackgroundLogin;
    }

    .results-circle.red {
        background: $error;
    }

    .results-circle.orange {
        background: $mainColor;
    }

    .results-circle.green {
        background: $success;
    }

    .results-circle.full-green {
        background: #a1c225;
    }

    .results-main .results-name {
        font-size:15px;
        font-weight:700
    }

    .scores-list .scores-main .results-name, .scores-list .scores-main .results-value  {
        font-size:25px;
        font-weight:700
    }

    .row-secondary .scores-list .scores-main .results-name,  .row-secondary .scores-list .scores-main .results-value  {
        font-size:18px;
        font-weight:700
    }

    .results-score .results-value {
        height:60px;
        // width:60px;
        // min-width: 60px;
        // background: #fff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color:$textColor;
    }

    .row-secondary .results-score .results-value {
        height:auto;
    }

    .results-score .green .results-value, .results-score  .green .results-name  {
        color:$success;
    }

    .results-score  .red .results-value, .results-score  .red .results-name  {
        color:$error;
    }

    .results-score  .full-green .results-value, .results-score  .full-green .results-name  {
        color:#a1c225;
    }

    // .results-score .results-name{
    //     color:#fff;
    // }   

    .results-main .results-circle {
        width:31px;
        height:31px;
    }

    .scores-list .results-name {
        font-size:15px;
    }

    .results-name {margin-right: 5px;}

    .score-max {
        font-size:10px;
    }

    .results-circle-container{
        display: flex;
        justify-content: flex-end;
    }

    .results-circle-item {
        width: 35px;
        display:flex;
        justify-content: center;
    }

    .results-periods {
        display: flex;
        justify-content: flex-end;
    }

    .results-periods-item {
        width: 35px;
        display:flex;
        justify-content: center;
    }
</style>