<template>
    <div class="pathology" v-if="!loading">
        <div class="back" @click="$router.go(-1)"></div>
        <h1>{{ `${patient.firstname} ${patient.lastname}`}}</h1>
        <h2>{{ JSON.parse(pathology.name)[this.$store.state.LANG] }} - {{ JSON.parse(periodName)[this.$store.state.LANG] }}</h2>
        <div class="acceptance-waiting" v-if="!pathology.acceptance">{{ $t('patient.waitingAcceptance')}}</div>
        <div class="timeline-container">
            <PathologyStep
                v-for="(item, idx) in pathologyStep"
                :key="idx" :step="item"
                v-on:switchPeriod="switchPeriod"
                :periodActive="periodActive"
            >
            </PathologyStep>
        </div>
        <template v-if="loadingStep">
            <div class="loader-switch">
                <Loader></Loader>
            </div>
        </template>
        <template v-else>
            <template v-if="periodActive != 2">
                <Results
                    :periodActive="periodActive"
                    :max="pathology.max"
                    :periodName="periodName"
                    :refresh="refresh"
                >
                </Results>
                <PathologySubStep
                    :steps="steps"
                >
                </PathologySubStep>
            
                <PathologyQuestionsForm
                    :period="periodActive "
                    :steps="steps"
                    v-on:refreshResults="refreshResults()"
                >
                </PathologyQuestionsForm>
            </template>
            <template v-else>
                <FormInjection></FormInjection>
            </template>
        </template>
    </div>
</template>

<script>
import PathologyStep from './../../components/patient/PathologyStep'
import PathologySubStep from './../../components/patient/PathologySubStep'
import PathologyQuestionsForm from './../../components/forms/PathologyQuestionsForm'
import FormInjection from './../../components/forms/FormInjection'
import Loader from './../../components/Loader'
import Results from './../../components/Results'


import store from './../../store'
import axios from 'axios'

export default {
    name : 'CoordinatorMonitoring',
    components : {
        PathologyStep,
        PathologySubStep,
        PathologyQuestionsForm,
        FormInjection,
        Loader,
        Results
    },
    data() {
        return {
            steps : null,
            periodActive : null,
            pathologyStep : [],
            pathology : null,
            patient : null,
            loading : true,
            periodName : null,
            loadingStep : false,
            refresh : 0
        }
    },
    methods : {
        switchPeriod(id, name) {
            this.periodActive = id
            this.periodName = name
        },
        refreshResults() {
            this.refresh++;
            let vm = this
            let config = {
                validateStatus: () => true,
            }
            if(store.getters.getToken) {
                config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
            }
            axios
                .get(store.getters.getURL +"monitoring/get_infos/" + this.$route.params.id, config)
                .then(function (response) {
                    vm.pathologyStep = response.data.periods
                })
        }
 
    },
    watch : {
        periodActive() {
            let config = {
                validateStatus: () => true,
            }
            if(store.getters.getToken) {
                config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
            }

            let vm = this
            this.loadingStep = true

            axios
                .get(store.getters.getURL +"monitoring/get_steps/" +  this.periodActive, config)
                .then(function (response) {
                    vm.loadingStep= false
                    if(response.status === 200) {
                        vm.steps = response.data.steps
                    }else {
                        store.dispatch('addNotif', {type : 2, content : JSON.parse(response.data.message)[store.state.LANG]})
                    }
                })
                .catch((err) => {
                    vm.loading = false
                    store.dispatch('addNotif', {type : 2, content : err})
                });
        }
    },
    mounted() {
        let config = {
            validateStatus: () => true,
        }
        if(store.getters.getToken) {
            config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
        }

        let vm = this

        axios
            .get(store.getters.getURL +"monitoring/get_infos/" + this.$route.params.id, config)
            .then(function (response) {
                vm.loading = false
                vm.patient = response.data.user 
                vm.pathology = response.data.pathology
                vm.pathologyStep = response.data.periods
                vm.periodName = vm.pathologyStep.find(e => e.id === vm.pathology.current_period).name
                store.commit('setCurrentModule', response.data.pathology.current_module)
                if(vm.$route.params.step && vm.$route.params.step == 2) {
                    vm.periodActive = 2
                }else {
                    vm.periodActive = response.data.pathology.current_period
                }
            })
            .catch((err) => {
                store.dispatch('addNotif', {type : 2, content : err})
            });
        

    }
}
</script>

<style lang="scss" scoped>

    .back {
        position:absolute;
        left:30px;
        top:18px;
    }

    .pathology  {   
        background: #fff;
        border-radius:10px;
        box-shadow: 0 2px 5px rgba(0,0,0,0.5);
        margin:0 auto 80px auto ;
        padding: 20px 40px;
        width:$boxWidth;
        max-width: calc(90% - 80px);
        position:relative;
        min-height: calc(100vh - 351px);
    }

    h1 {
        text-align: center;
        margin-bottom: 10px;
        font-size: 25px;
        font-family: $fontBlack;
        letter-spacing: 1px;
        text-transform:uppercase;
    }

    h2 {
        text-align: center;
        font-size:25px;
        margin-bottom: 10px;
        color:$textGrey
    }

    .results-container{
        display: flex;
        justify-content: center;
        margin:40px auto;
    }

    form {
        display: flex;
        flex-direction: column;
        margin:40px auto;
        width:1000px;
        max-width: 90%;
    }

    .btn {
        width: 200px;
    }

    .submit-row {
        display: flex;
        justify-content: center;
        margin-top:20px;
    }

    .acceptance-waiting {
        display: flex;
        justify-content: center;
        background:$error;
        color:#fff;
        padding:10px;
        border-radius: 10px;
        font-weight: 700;
        margin-top:20px;
    }

    .loader-switch {
        display: flex;
        justify-content: center;
        align-items: center;
        height:300px;
    }
</style>